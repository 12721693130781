@font-face {
  font-family: robo-font;
  src: url("../assets/plasmatic.ttf");
}

@media only screen and (max-width: 600px) {

  /********************* BackGrounds *********************/

  .bg-landing {
    background-image: url('../assets/Moon.gif');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    z-index: -100;
  }

  .bg-portfolio {
    /* background-image: url(https://media.giphy.com/media/xUOwGcu6wd0cXBj5n2/giphy.gif); */
    background-image: url("../assets/OutRun.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
    z-index: -100;
  }

  .bg-about {
    background-image: url('../assets/CircleDark.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
    z-index: -100;
  }

  .bg-contact {
    background-image: url("../assets/BlazingSun.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
    position: initial;
    z-index: -100;
  }

  .bg-fade-one {
    background: rgb(35, 5, 68);
    background: linear-gradient(180deg,
        rgba(35, 5, 68, 1) 0%,
        rgba(21, 31, 43, 1) 100%);
    width: 100vw;
    height: 200vh;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: -100;
  }

  .bg-fade-two {
    background: rgba(4, 32, 46, 1);
    background: linear-gradient(180deg,
        rgba(4, 32, 46, 1) 0%,
        rgba(179, 164, 224, 100) 100%);
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 200vh;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: -20em 0em 0em 0em; */
    position: relative;
    z-index: -100;
  }

  .bg-fade-three {
    background: rgba(130, 189, 227, 100);
    background: linear-gradient(180deg,
        rgba(130, 189, 227, 100) 0%,
        rgba(255, 227, 197, 1) 100%);
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 200vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: -20em 0em 0em 0em; */
    position: relative;
    z-index: -100;
  }

  /********************* Navbar *********************/

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    padding: .5em;
    position: fixed !important;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em;
  }

  .nav-button {
    padding: 0em;
    border: solid 3px #9538c6;
    border-radius: 50%;
    color: #9538c6;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2em;
    height: 60px;
    width: 60px;
    cursor: pointer;
    transition: 5s ease-in-out;
    animation: color-fade 8s infinite linear;
  }

  .rotate-icon {
    transition: 5s ease-in-out;
    animation: rotate-icon 5s infinite linear;
    transform-origin: center 50% 0;
  }



  @keyframes rotate-icon {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /********************* Title/Header *********************/

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10em 1em 0em 1em;
    z-index: -50;
  }

  .row-title {
    padding: .5em 0em;
    margin: 0em -1em;
  }


  .title-resume,
  .title-misc,
  .title-name,
  .scroll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-weight: 700;
    padding: 1em 0em;
    text-shadow: 1px 1px #1f032e;
  }

  .title-name {
    font-size: 2em;
  }

  .title-resume {
    font-size: 1.5em;
  }

  .title-misc {
    font-size: 1.5em;
  }

  .scroll {
    font-size: 1em;
    padding-top: 5em;
  }

  /********************* Choices  *********************/

  .choice-container {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-content: space-between;
    align-items: center;
    position: fixed;
    margin-top: 6em;
    width: 95%;
    z-index: 200;
  }

  .choice-box-one,
  .choice-box-two,
  .choice-box-three,
  .choice-box-four {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: robo-font;
    color: white;
    font-size: 1.3em;
    padding: 0.1em .25em;
    margin: -0.5em 0em;
    text-shadow: .5px .5px #1f032e;
    /* background-color: rgba(0, 0, 0, 0.3); */
    /* border: 1px solid white; */
  }

  .choice-box-one:hover,
  .choice-box-two:hover,
  .choice-box-three:hover,
  .choice-box-four:hover {
    font-size: 1.5em;
    animation: color-fade-text 5s infinite linear;
    /* color: #D432B9; */
  }

  .slide-animation-one {
    -webkit-animation: slide-in-top 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .slide-animation-two {
    -webkit-animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
    animation-delay: 0.5s;
  }

  .slide-animation-three {
    -webkit-animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
    animation-delay: 1s;
  }

  .slide-animation-four {
    -webkit-animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-right 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s both;
    animation-delay: 1.5s;
  }


  /********************** Fade One | Dot **********************/


  .dot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .dot-text-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70em;
    /* padding: 2em 0em; */
  }

  .dot-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20em;
    height: 20em;
    background: linear-gradient(180deg,
        #9538c6,
        #f5c9aa,
        #d93a61,
        #f2786e,
        #3c2d9b,
        #d432b9,
        #9538c6);
    background-size: 500% 500%;
    border-radius: 50%;
    animation: color-fade-bg-linear 5s ease-in-out infinite;
    margin: 1.5em 0em;
    box-shadow:
      0 0 5px 1px #f5c9aa,
      /* inner yellow */
      0 0 10px 5px #F2786E,
      /* middle orange */
      0 0 40px 15px #3c2d9b;
    /* outer purple */
  }

  .dot-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20em;
    height: 20em;
    background: linear-gradient(180deg,
        #f5c9aa,
        #d432b9,
        #9538c6,
        #d93a61,
        #f2786e,
        #3c2d9b,
        #9538c6);
    background-size: 500% 500%;
    border-radius: 50%;
    animation: color-fade-bg-linear 5s ease-in-out infinite;
    margin: 1.5em 0em;
    box-shadow:
      0 0 5px 1px #f5c9aa,
      /* inner yellow */
      0 0 10px 5px #F2786E,
      /* middle orange */
      0 0 40px 15px #3c2d9b;
    /* outer purple */

  }


  .dot-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 2px;
    font-size: 1em;
    font-weight: 700;
    text-shadow: 0.5px 0.5px #1f032e;
    padding: 0em 0em .75em 0em;
    margin: 0em;
  }

  h6.dot-title {
    font-size: 1.25em;
    font-weight: 700;
    text-shadow: 0.5px 0.5px #1f032e;
    margin: 0em;
  }



  /********************* Portfolio *********************/


  .portfolio-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12em 0em 0em 0em;
  }

  h1.portfolio-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-weight: 300;
    font-size: 2em;
    text-shadow: 1px 1px #1f032e;
  }

  h4.port-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 1em;
    text-shadow: 1px 1px #1f032e;
    padding: 1em 0em;
    margin: 0em 0em;
  }

  .port-box-one,
  .port-box-two {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: .5em 0em;
    margin: 1em 2em;
    border: solid 3px#9538C6;
    border-radius: 3px;
    animation: border-color-fade 8s infinite linear;
  }

  .main-box {
    padding: 0em 0em;
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  /********************** Fade Two **********************/

  .fade-two-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .rectangle-one,
  .rectangle-two,
  .rectangle-three,
  .rectangle-four,
  .rectangle-five,
  .rectangle-six,
  .rectangle-seven {
    border-radius: 3px;
    background-color: #9538c6;
    padding: 1em 8em;
    animation: color-fade-bg 10s linear infinite;
    animation-delay: 0.5s;
    z-index: 200;
  }

  .rectangle-one:hover,
  .rectangle-two:hover,
  .rectangle-three:hover,
  .rectangle-four:hover,
  .rectangle-five:hover,
  .rectangle-six:hover,
  .rectangle-seven:hover {
    transform: rotate(180deg);
    cursor: pointer;
  }

  .rect-row {
    padding: 5em 0em;
  }

  /********************* About *********************/

  .about-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12em 1em 0em 1em;
  }

  h1.about-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 2em;
    font-weight: 300;
    text-shadow: .75px .75px #1f032e;
  }

  h3.about-text {
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 1em;
    text-shadow: .75px .75px #1f032e;
  }

  .abt-txt-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4em 2em;
  }

  /********************** Fade Three **********************/

  .tri-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 75em;
    /* width: 50em; */
  }

  #pulse-one {
    content: "";
    display: block;
    margin: auto;
    width: 10em;
    height: 10em;
    border: 10px solid #3cefff;
    border-radius: 50%;
    animation: pulse-two 1s alternate ease-in-out infinite,
      color-fade-pulse 2s linear infinite;
  }

  #pulse-two {
    content: "";
    display: block;
    margin: auto;
    width: 10em;
    height: 10em;
    border: 10px solid #3cefff;
    border-radius: 50%;
    animation: pulse-one 1s alternate ease-in-out infinite,
      color-fade-pulse 3s linear infinite;
  }

  #pulse-one:hover,
  #pulse-two:hover {
    border-radius: 100%;
    cursor: pointer !important;
  }


  @keyframes pulse-one {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes pulse-two {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0.5);
    }
  }

  @keyframes color-fade-pulse {
    0% {
      border: solid 3px rgba(149, 56, 198, 1);
      /* background-color: rgba(149, 56, 198, .5); */
    }

    15% {
      border: solid 3px rgba(217, 58, 97, 1);
      /* background-color: rgba(217, 58, 97, .5); */
    }

    30% {
      border: solid 3px rgba(245, 201, 170, 1);
      /* background-color: rgba(245, 201, 170, .5); */
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      border: solid 3px rgba(242, 120, 110, 1);
      /* background-color: rgba(242, 120, 110, .5); */
    }

    60% {
      border: solid 3px rgba(60, 45, 155, 01);
      /* background-color: rgba(60, 45, 155, 0.5); */
    }

    75% {
      border: solid 3px rgba(212, 50, 185, 1);
      /* background-color: rgba(212, 50, 185, .5); */
    }

    100% {
      border: solid 3px rgba(149, 56, 198, 1);
      /* background-color: rgba(149, 56, 198, .5); */
    }
  }


  /********************** Contact  **********************/

  .contact-h-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10em 1em 0em 1em;
  }


  h1.contact-header {
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 2em;
    font-weight: 300;
    text-shadow: 1px 1px #1f032e;
  }

  .contact-box-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: .75em 0em;
    margin: 1.5em 2em;
    border: solid 3px#9538C6;
    border-radius: 3px;
    animation: border-color-fade 8s infinite linear;
  }

  .contact-box-one-social {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: .75em 0em;
    margin: 2em 2em;
    border: solid 3px#9538C6;
    border-radius: 3px;
    animation: border-color-fade 8s infinite linear;
  }

  .contact-box-one:hover,
  .contact-box-one-social:hover {
    background-color: rgba(137, 70, 166, 0.2);
    transition: 1s;
  }

  .contact-p {
    color: white !important;
    text-shadow: 1px 1px #1f032e;
    font-family: robo-font;
    letter-spacing: 3px;
  }

  .contact-p-social {
    color: white !important;
    text-shadow: 0.5px 0.5px #1f032e;
    font-family: robo-font;
    letter-spacing: 3px;
  }

  .contact-main-box {
    padding: 3em 0em 0em 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: flex-end; */
  }

  .cont-a {
    padding: 0.1em;
    margin: 0.1em;
  }

  .social {
    color: white;
  }

  .art-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2em 0em;
  }

  .artwork-cred {
    text-shadow: 0.5px 0.5px #1f032e;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 1em;
    animation: color-fade-text 6s linear infinite;
  }

  a.art {
    animation: color-fade-text 6s linear infinite;
    color: white !important;
  }


  /********************* MISC *********************/

  /* .padding {
    padding: 3.5em 0em;
    margin: 2em 0em;
  } */

  /********************* KeyFrames and Animations *********************/

  @keyframes color-fade {
    0% {
      border: solid 3px#9538C6;
      color: #9538c6;
    }

    15% {
      border: solid 3px #d93a61;
      color: #d93a61;
    }

    30% {
      border: solid 3px #f0d86b;
      color: #f0d86b;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      border: solid 3px #28bd6e;
      color: #28bd6e;
    }

    60% {
      border: solid 3px #3c2d9b;
      color: #3c2d9b;
    }

    75% {
      border: solid 3px #d432b9;
      color: #d432b9;
    }

    90% {
      border: solid 3px #ce3e37;
      color: #ce3e37;
    }
  }

  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes color-fade-text {
    0% {
      color: white;
    }

    15% {
      color: #d93a61;
    }

    30% {
      color: #f0d86b;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      color: #28bd6e;
    }

    60% {
      color: #9538c6;
    }

    75% {
      color: #d432b9;
    }

    90% {
      color: #3c2d9b;
    }
  }

  @keyframes border-color-fade {
    0% {
      border: solid 3px#9538C6;
    }

    15% {
      border: solid 3px #d93a61;
    }

    30% {
      border: solid 3px #f0d86b;
    }

    45% {
      border: solid 3px #28bd6e;
    }

    60% {
      border: solid 3px #3c2d9b;
    }

    75% {
      border: solid 3px #d432b9;
    }

    90% {
      border: solid 3px #ce3e37;
    }
  }

  @keyframes color-fade-bg {
    0% {
      background-color: #9538c6;
    }

    15% {
      background-color: #d93a61;
    }

    30% {
      background-color: #f5c9aa;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      background-color: #f2786e;
    }

    60% {
      background-color: #3c2d9b;
    }

    75% {
      background-color: #d432b9;
    }

    100% {
      background-color: #9538c6;
    }
  }


}