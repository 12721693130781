/* Colors */
/*  
#9538C6
#D93A61
#3C2D9B
#2B226E
#F0D86B
#CE3E37
#D432B9
#F2786E
*/

/********************* FONTS *********************/

@font-face {
  font-family: robo-font;
  src: url("./assets/plasmatic.ttf");
}

ion-icon {
  font-size: 3em;
}

@media only screen and (min-width: 601px) {
  /*********************Backgrounds *********************/

  .bg-landing {
    background-image: url(./assets/Moon.gif);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: initial;
    z-index: -100 !important;
  }

  .bg-portfolio {
    background-image: url(./assets/OutRun.gif);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: initial;
    z-index: -100 !important;
  }

  .bg-about {
    background-image: url(./assets/CircleDark.gif);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: initial;
    z-index: -100;
  }

  .bg-contact {
    background-image: url("./assets/BlazingSun.gif");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: initial;
    z-index: -100;
  }

  .bg-fade-one {
    background: rgb(35, 5, 68);
    background: linear-gradient(180deg,
        rgba(35, 5, 68, 1) 0%,
        rgba(21, 31, 43, 1) 100%);
    width: 100vw;
    height: 200vh;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: -100;
  }

  .bg-fade-two {
    background: rgba(4, 32, 46, 1);
    background: linear-gradient(180deg,
        rgba(4, 32, 46, 1) 0%,
        rgba(179, 164, 224, 100) 100%);
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 200vh;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: -20em 0em 0em 0em; */
    position: relative;
    z-index: -100;
  }

  .bg-fade-three {
    background: rgba(130, 189, 227, 100);
    background: linear-gradient(180deg,
        rgba(130, 189, 227, 100) 0%,
        rgba(255, 227, 197, 1) 100%);
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 200vh;
    /* margin: -20em 0em 0em 0em; */
    position: relative;
    z-index: -100;
  }

  .bg-end-fade {
    background: #8946a6;
    background: linear-gradient(180deg, #8946a6 0%, #ffcec2 100%);
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    /* margin: -20em 0em 0em 0em; */
    position: relative;
    z-index: -100;
  }

  /********************* INDEX *********************/

  #behind-index {
    z-index: -10;
  }

  #nav-index {
    z-index: 100;
  }

  /********************* NavBar *********************/

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5em 5em;
    position: fixed !important;
    z-index: 10;
  }

  .nav-button {
    padding: 0em;
    border: solid 2px #9538c6;
    border-radius: 50%;
    color: #9538c6;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2em;
    height: 60px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s ease-in-out;
    animation: color-fade-border 8s infinite linear;
    z-index: 2;
  }

  .nav-button:hover {
    transform: scale(1.3, 1.3);
    transition: 1s;
    padding: 0.5em;
    z-index: 2;
  }

  .rotate-icon {
    transition: 5s ease-in-out;
    animation: rotate-icon 5s infinite linear;
    transform-origin: center 50% 0;
    z-index: 2;
  }

  /********************* Header/Title *********************/

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-end;
    padding: 1em;
  }

  .title-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .row-title {
    padding: 3em 2em 0em 0em;
    width: 100%;
    /* border-bottom: 1px solid white; */
  }

  h1.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 10px;
    font-size: 4em;
    font-weight: 500;
    text-shadow: 2px 2px black;
  }

  h3.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 2.5em;
    font-weight: 500;
    text-shadow: 1px 1px black;
  }

  h4.title-resume {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 2em;
    font-weight: 500;
    text-shadow: 1px 1px black;
    padding: 0em 0em 0em 0em;
    padding: 0.25em;
  }

  .title-scroll {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    color: white;
    font-family: robo-font;
    letter-spacing: 6px;
    font-size: 1em;
    font-weight: 500;
    text-shadow: 1px 1px black;
    padding: 2em;
  }

  /********************** Resume Animation **********************/

  .title-resume {
    position: relative;
    border: 2px #1f032e;
  }

  .title-resume::before,
  .title-resume::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #9538c6;
    transform-origin: center;
    transform: scaleY(0);
    transition: transform 0.5s ease;
  }

  .title-resume::before {
    left: -8px;
  }

  .title-resume::after {
    right: -8px;
  }

  .title-resume:hover::before,
  .title-resume:hover::after {
    transform: scaleY(1);
    animation: color-fade-border 5s infinite linear;
  }

  h5.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 12px;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1.3px 1.3px black;
    padding: 0em;
    margin: 0em;
  }

  h6.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    font-family: robo-font;
    letter-spacing: 1px;
    font-size: 1em;
    font-weight: 700;
    padding: 0em 0em;
    text-shadow: 1.3px 1.3px black;
    margin: 0.5em;
  }

  p.title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    color: white;
    font-family: robo-font;
    letter-spacing: 1px;
    font-size: 1em;
    font-weight: 700;
    margin: 0em;
    /* padding: .2em 0em; */
    text-shadow: 1.3px 1.3px black;
  }

  /********************* Choices  *********************/

  /* What keeps the menu side bar with spinning button */
  .choice-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    position: fixed;
    margin-top: 15em;
    margin-left: -2em;
    z-index: 300;
    transition: .5s ease-in-out;
  }

  .choice-box-one,
  .choice-box-two,
  .choice-box-three,
  .choice-box-four,
  .choice-box-skills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: robo-font;
    color: white;
    font-size: 2em;
    padding: 1em 3em;
    width: 100%;
    z-index: 300;
    text-shadow: 1px 1px #1f032e;
    transition: .5s ease-in-out;
  }

  .choice-box-one:hover,
  .choice-box-two:hover,
  .choice-box-three:hover,
  .choice-box-four:hover,
  .choice-box-skills:hover {
    width: 120%;
    transition: .5s ease-in-out;
    /* animation: color-fade-text 2s infinite linear; */
  }

  a {
    color: white !important;
  }

  .active {
    transition: 5s ease-in-out;
    animation: color-fade-text 5s infinite linear;
  }

  /********************* Choice-Animations *********************/

  .slide-in-left-one {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.25s;
    transition: 1s ease-in-out;
  }
  
  .slide-in-left-one::after {
    animation: slide-out-right 1s cubic-bezier(0.94, 0.45, 0.46, 0.25) both;
    animation-delay: 0.25s;
    transition: 1s ease-in-out;

  }

  .slide-in-left-skills {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.25s;
  }

  .slide-in-left-two {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.5s;
  }

  .slide-in-left-three {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.75s;
  }

  .slide-in-left-four {
    -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 1s;
  }

  /********************** Portfolio  *********************/

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5em 5em;
    /* color: white; */
  }

  h1.portfolio {
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 3em;
    text-shadow: 0.5px 0.5px #1f032e;

  }

  .main-box {
    padding: 0em 0em;
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    /* align-content: flex-end; */
  }

  h4.port-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    color: white;
    font-family: robo-font;
    letter-spacing: 5px;
    font-size: 1.25em;
    text-shadow: 1.25px 1.25px #1f032e;
    margin: 0em 0em;
  }

  .port-box-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 2em 8em;
    margin: 1em 0em;
    border: solid 5px#9538C6;
    border-radius: 3px;
    animation: color-fade-border 8s infinite linear;
  }

  .port-box-one:hover {
    animation: color-fade-border 8s infinite linear;
    background-color: rgba(40, 0, 25, 0.2);
    transition: 1s;
  }

  /********************* About Page **********************/

  .about-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5em 5em;
    /* color: white; */
  }

  h1.about {
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 3em;
    text-shadow: 0.5px 0.5px #1f032e;
  }

  h3.about-text {
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 1.25em;
    text-shadow: 1.25px 1.25px #1f032e;
  }

  .abt-txt-cont {
    padding: 0em 0em;
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end; */
    /* background-color: rgba(0, 0, 0, .5); */
    padding-top: 15em;
  }

  /********************* Contact  *********************/

  .contact-h-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5em 5em;
  }

  h1.contact-header {
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 3em;
    text-shadow: 1px 1px #1f032e;
  }

  .contact-box-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2em 4em;
    margin: 2em 0em;
    border: solid 5px#9538C6;
    border-radius: 3px;
    animation: color-fade-border 8s infinite linear;
  }

  .contact-box-one-social {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2em 4em;
    margin: 2em 0em;
    border: solid 5px#9538C6;
    border-radius: 3px;
    animation: color-fade-border 8s infinite linear;
  }

  .contact-box-one:hover,
  .contact-box-one-social:hover {
    background-color: rgba(137, 70, 166, 0.2);
    transition: 1s;
  }

  .contact-p {
    color: white !important;
    text-shadow: 1px 1px #1f032e;
    font-family: robo-font;
    letter-spacing: 3px;
  }

  .contact-p-social {
    color: white !important;
    text-shadow: 0.5px 0.5px #1f032e;
    font-family: robo-font;
    letter-spacing: 3px;
  }
  
  .contact-main-box {
    padding: 3em 0em 0em 0em;
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    /* align-content: flex-end; */
  }
  
  .cont-a {
    padding: 0.1em;
    margin: 0.1em;
  }
  
  .social {
    color: white;
  }
  
  .art-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .artwork-cred {
    /* color: white; */
    text-shadow: 0.5px 0.5px #1f032e;
    font-family: robo-font;
    letter-spacing: 3px;
    font-size: 1em;
    animation: color-fade-text 6s linear infinite;
  }
  
  a.art:hover {
    animation: color-fade-text 4s linear infinite;
    transition: 2s;
    letter-spacing: 6px;
  }

  /********************* Fade One Dot  *********************/

  .dot-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .dot-text-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .dot {
    width: 40em;
    height: 40em;
    /* justify-items: center;
    align-items: center; */
    /* padding: 25em; */
    background: linear-gradient(180deg,
        #9538c6,
        #f5c9aa,
        #d93a61,
        #f2786e,
        #3c2d9b,
        #d432b9,
        #9538c6);
    background-size: 300% 300%;
    /* background-color: #F2786E; */
    border-radius: 50%;
    animation: color-fade-bg-linear 5s ease-in-out infinite;
    box-shadow:
      0 0 5px 1px #f5c9aa,
      /* inner yellow */
      0 0 10px 5px #F2786E,
      /* middle orange */
      0 0 40px 15px #3c2d9b;
    /* outer purple */
  }

  .dot-row-title {
    padding: 5em 1em 3em 1em;
    width: 100%;
    /* border-bottom: 1px solid white; */
  }

  h4.dot-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 4px;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1.1px 1.1px #1f032e;
    padding: 0em 0em 1.25em 0em;
    margin: 0em;
  }

  h5.dot-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 4px;
    font-size: 1em;
    font-weight: 700;
    text-shadow: 1.1px 1.1px #1f032e;
    padding: 0em 0em 1.25em 0em;
    margin: 0em;
  }

  h6.dot-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 4px;
    font-size: 1.25em;
    font-weight: 700;
    text-shadow: 1.1px 1.1px #1f032e;
    padding: 0em 0em 1.25em 0em;
    margin: 0em;
  }

  p.dot-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-family: robo-font;
    letter-spacing: 4px;
    font-size: 1em;
    font-weight: 700;
    text-shadow: 1.1px 1.1px #1f032e;
    padding: 0em 0em 1.25em 0em;
    margin: 0em;
  }

  /*********************  Fade Two *********************/

  .fade-two-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  .rectangle-one,
  .rectangle-two,
  .rectangle-three,
  .rectangle-four,
  .rectangle-five,
  .rectangle-six,
  .rectangle-seven {
    border-radius: 3px;
    background-color: #9538c6;
    padding: 1em 15em;
    animation: color-fade-bg 10s linear infinite;
    animation-delay: 0.5s;
    z-index: 200;
  }

  .rectangle-one:hover,
  .rectangle-two:hover,
  .rectangle-three:hover,
  .rectangle-four:hover,
  .rectangle-five:hover,
  .rectangle-six:hover,
  .rectangle-seven:hover {
    transform: rotate(180deg);
    cursor: pointer;
  }

  .rect-row {
    padding: 5em 0em;
  }

  h1.fade-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: white;
    font-family: robo-font;
    letter-spacing: 8px;
    font-size: 2em;
    font-weight: 700;
    text-shadow: 0.5px 0.5px #1f032e;
    padding: 5em 0em 1.25em 0em;
    margin: -10em 0em -10em 0em;
    transform: rotate(90deg)
  }

  /********************* Fade Three  *********************/

  .tri-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100em;
    /* width: 50em; */
  }

  #pulse-one {
    content: "";
    display: block;
    margin: auto;
    width: 10em;
    height: 10em;
    border: 10px solid #3cefff;
    border-radius: 50%;
    animation: pulse-two 1s alternate ease-in-out infinite,
      color-fade-pulse 2s linear infinite;
  }

  #pulse-two {
    content: "";
    display: block;
    margin: auto;
    width: 10em;
    height: 10em;
    border: 10px solid #3cefff;
    border-radius: 50%;
    animation: pulse-one 1s alternate ease-in-out infinite,
      color-fade-pulse 3s linear infinite;
  }

  #pulse-one:hover,
  #pulse-two:hover {
    border-radius: 100%;
    cursor: pointer !important;
  }


  @keyframes pulse-one {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes pulse-two {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0.5);
    }
  }

  /********************* KeyFrames and Animations *********************/

  /* 
  .dots-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .dots {
    width: 100em;
    height: 100em;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    padding: 25em;
  } */

  /* .dots>div {
    width: 8em;
    height: 8em;
    background-color: #F2786E;
    border-radius: 50%;
    animation: color-fade-bg 8s infinite;
  }

  .dots>div:nth-of-type(2),
  .dots>div:nth-of-type(4) {
    animation-delay: 0.5s;
  }

  .dots>div:nth-of-type(3),
  .dots>div:nth-of-type(5),
  .dots>div:nth-of-type(7) {
    animation-delay: 0.75s;
  }

  .dots>div:nth-of-type(6),
  .dots>div:nth-of-type(8) {
    animation-delay: 1s;
  }

  .dots>div:nth-of-type(9) {
    animation-delay: 1.25s;
  } */

  @keyframes fade {
    to {
      opacity: 0.2;
    }
  }

  @keyframes type {
    from {
      width: 0;
    }
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 0;
    }
    
    100% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-bottom-fade {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
      transform: translateY(1) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom-fade {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5);
      transform: translateY(1000px) scaleY(2.5);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
      filter: blur(40px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0) scaleY(1);
      transform: translateY(0) scaleY(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-filter: blur(0);
      filter: blur(0);
      opacity: 1;
    }
  }

  @keyframes color-fade-text {
    0% {
      color: white;
    }

    15% {
      color: #d93a61;
    }

    30% {
      color: #f5c9aa;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      color: #f2786e;
    }

    60% {
      color: #9538c6;
    }

    75% {
      color: #d432b9;
    }

    90% {
      color: #3c2d9b;
    }
  }

  @keyframes color-fade-border {
    0% {
      border: solid 1.5px #9538c6;
      color: #9538c6;
    }

    15% {
      border: solid 1.5px #d93a61;
      color: #d93a61;
    }

    30% {
      border: solid 1.5px #f5c9aa;
      color: #f5c9aa;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      border: solid 1.5px #f2786e;
      color: #f2786e;
    }

    60% {
      border: solid 1.5px #3c2d9b;
      color: #3c2d9b;
    }

    75% {
      border: solid 1.5px #d432b9;
      color: #d432b9;
    }

    100% {
      border: solid 1.5px #9538c6;
      color: #9538c6;
    }
  }

  @keyframes color-fade-pulse {
    0% {
      border: solid 3px rgba(149, 56, 198, 1);
      /* background-color: rgba(149, 56, 198, .5); */
    }

    15% {
      border: solid 3px rgba(217, 58, 97, 1);
      /* background-color: rgba(217, 58, 97, .5); */
    }

    30% {
      border: solid 3px rgba(245, 201, 170, 1);
      /* background-color: rgba(245, 201, 170, .5); */
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      border: solid 3px rgba(242, 120, 110, 1);
      /* background-color: rgba(242, 120, 110, .5); */
    }

    60% {
      border: solid 3px rgba(60, 45, 155, 01);
      /* background-color: rgba(60, 45, 155, 0.5); */
    }

    75% {
      border: solid 3px rgba(212, 50, 185, 1);
      /* background-color: rgba(212, 50, 185, .5); */
    }

    100% {
      border: solid 3px rgba(149, 56, 198, 1);
      /* background-color: rgba(149, 56, 198, .5); */
    }
  }

  @keyframes color-fade-bg {
    0% {
      background-color: #9538c6;
    }

    15% {
      background-color: #d93a61;
    }

    30% {
      background-color: #f5c9aa;
      /* text-shadow: .1px .1px white; */
      /* background-color: white */
    }

    45% {
      background-color: #f2786e;
    }

    60% {
      background-color: #3c2d9b;
    }

    75% {
      background-color: #d432b9;
    }

    100% {
      background-color: #9538c6;
    }
  }

  @keyframes color-fade-bg-linear {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes rotate-icon {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}